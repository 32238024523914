export default {
  props: ["nameScreen"],
  data() {
    return {
      menu: false,
      sSearch: "",
      dates: null,
      dateStart: null,
      dateEnd: null,
    };
  },
  mounted() {
    this.$store.commit("setSearch", "");
    // this.$store.commit("setStartDate", null);
    // this.$store.commit("setEndDate", null);
  },
  methods: {
    setDates() {
      this.dateStart = this.dates[0];
      this.dateEnd = this.dates[1];
    },
    focusSearch() {
      this.$refs.sSearch.focus();
    },
  },
  watch: {
    dateStart: function () {
      // this.$store.commit("setStartDate", this.dateStart);
    },
    dateEnd: function () {
      // this.$store.commit("setEndDate", this.dateEnd);
    },
    // set state to local search
    sSearch: function () {
      this.$store.commit("setSearch", this.sSearch);
    },
  },
};